<template>
  <div>
    <div class="user-title">
      <h2>我的订单</h2>
    </div>
    <div id="user_order_list">
      <div class="user-order-list user-order-list-special">
        <dl class="item" v-for="(order,index) in orders" v-bind:key="index">
          <dt class="item-t item-t-qb">
            <div class="t-statu">
              <div class="t-statu-name" id="ss_received_5980">
                已确认，未付款，未发货
              </div>
            </div>
            <div class="t-info">
              <span class="info-item">订单号：{{order.no}}</span>
              <span class="info-item">{{ order.createTime }}</span>
            </div>
            <div class="t-price"><em>¥</em>{{order.payAmount}}</div>
          </dt>
          <dd class="item-c" v-for="(item,i) in order.goods" v-bind:key="i">
            <div class="c-left">
              <div class="c-goods" >
                <div class="c-img">
                  <router-link :to="{path:'goods',query:{id:item.goodsId}}" target="_blank">
                    <img :src="getCoverImage(item.goodsImage)">
                  </router-link>
                </div>
                <div class="c-info">
                  <div class="o-info-lm">
                    <router-link :to="{path:'goods',query:{id:item.goodsId}}" target="_blank" class="info-name">
                      {{item.goodsName}}
                    </router-link>
                  </div>
                  <div class="fl">
                    <div class="info-price mr10" style="width: auto;">
                      <b><em>¥</em>{{item.goodsPrice}}</b><i>×</i><span>{{item.goodsQty}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="c-outhr">
              <span v-for="(para,index) in item.params" v-bind:key="index">{{para.name}}：{{para.value}}</span>
            </div>

            <div class="c-handle" v-if="i === 0">
              <a href="javascript:void(0)" class="sc-btn" v-on:click="toPay">支付订单</a>
            </div>
          </dd>
          <dt class="item-t" v-if="order.memo!=''" style="border-bottom: none;">
            备注：{{order.memo}}
          </dt>
        </dl>
      </div>

      <div class="pages pages_warp">
        <div class="item prev"><a href="javascript:user_order_gotoPage(0, 118, 0, 0);"><i class="iconfont icon-left"></i></a></div>
        <div class="item cur"><a spellcheck="false">1</a></div>
        <div class="item next"><a href="javascript:user_order_gotoPage(1, 118, 0, 0);"><i class="iconfont icon-right"></i></a></div>
      </div>
    </div>

    <wxpay ref="wxpay" v-if="wxpayVisible"></wxpay>
  </div>
</template>

<script>
import {orderList} from '@/api/mall/order'
import wxpay from "@/views/cart/pay/wxpay.vue";
export default {
  name: "OrderList",
  components: {wxpay},
  data() {
    return {
      orders:[],

      wxpayVisible: false,
    }
  },
  created() {
    this.getOrderList()
  },
  methods:{
    getOrderList(){
      orderList({
        currentPage: this.pageNow,
        pageSize: 20
      }).then(res => {
        this.orders = res.data.list
        this.orders.forEach((o)=>{
          o.goods.forEach((i)=>{
            if(i.paramsData!=null){
              i.params = JSON.parse(i.paramsData);
            }else{
              i.params = []
            }
          })
        })
      })
    },
    getCoverImage(images){
      let json = JSON.parse(images)
      return json[0].attachmentId == '' ? this.staticUrl + '/app/no-pic.png' : this.staticUrl + '/' + json[0].imageUrl
    },
    toPay(){
      this.wxpayVisible = true
      this.$nextTick(() => {
        this.$refs.wxpay.init()
      })
    }
  }
}
</script>

<style scoped>
.c-outhr{
  width: 310px !important;
  margin-left:0 !important;
  font-size: 14px !important;
}
.c-outhr span{
  display: block;

}
</style>
